<template>
    <v-app>
        <DashboardCoreView />
    </v-app>
</template>
<script>

export default {
    name: 'inicio',
    components: {
        DashboardCoreView: () => import('./View.vue')
    }
}
</script>
<style>
.iconosVcards {
  background-color:#b6cd3b;
  margin-bottom: 10px;
  padding: 25px;
  border-radius: 3px;
  position: relative;
  top: -25px;
}
.posicionBotonAgregar {
  position: relative;
  top: -12px;
}

</style>
